<template>
    <div class="payment_box" v-if="payInit">
        <div style="margin-top: 30px;font-weight:500;font-size:16px">支付中</div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { perCheck, checkHelped } from './api/api';
import pay from './api/pay';
import agreementDoc from './components/agreement-dec';
import { authChecker } from '@/common/js/mmcModule';
import { shareProj, tracking } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils';

import utils from '@/common/js/utils';
const params = utils.getRequestParams();
const pageKey = 'h5_jsb_paytableonepoint';

export default {
    data() {
        return {
            isWx: false,
            payInit: false,
            payment_type: 0,
        };
    },
    computed: {
        // ...mapState(['jsbrnd']),
    },
    mounted() {
        // alert(11)
        const that = this;
        this.isWx = utils.is_weixin();
        authChecker.check({ notneedphone: 1 }, () => {
            checkHelped(params.id).then(res => {
                if (res.code == 0) {
                    if (res.data.is_helped) {
                        alertMsg('您已经帮助过该项目');
                        setTimeout(() => {
                            window.location.href = '/fund/activity/onepoint/detail?id=' + params.id;
                        }, 2000);
                    } else {
                        that.getConfig();
                        that.initShare();
                    }
                }
            });
        });
    },
    components: {
        agreementDoc,
    },
    methods: {
        initShare() {
            const { id } = params;
            // const shareParams = {
            //     projuuid,
            //     shareRecord: {
            //         scene: pageKey,
            //     },
            // };
            // shareProj.init(shareParams);
        },

        getConfig() {
            const { id } = params;
            const that = this;
            perCheck({
                env: utils.is_weixin() ? 'wx' : 'h5',
                redirect_url: location.href,
                type: 1,
            }).then(res => {
                if (res.code === 0) {
                    that.payInit = true;
                    // document.title = res.data.project_name;
                    const {
                        redirect_url,
                        h5_payment_type,
                        wx_payment_type,
                    } = res.data;
                    that.payment_type = utils.is_weixin()
                        ? wx_payment_type
                        : h5_payment_type;
                    if (redirect_url && redirect_url !== '') {
                        window.location.replace(redirect_url);
                        return;
                    }

                    this.goPay();

                    
                }
            });
            this.reportData();
        },
        goPay() {
            if (!this.payInit) {
                alertMsg('初始化中，请稍后');
                return;
            }
            const { id, money } = params;
            const { payment_type } = this;
            if (money <= 0) {
                alertMsg('请输入正确金额');
                return;
            }
            pay({
                id: id,
                amount: Number(money),
                isSelect: false,
                pay_type: utils.is_weixin() ? 'wx' : 'h5',
                payment_type,
                anonymous: 0,
                comments: '',
                extra_data: {},
                success: () => {},
                fail: () => {},
                cancel: () => {},
            });
        },

        reportData() {
            tracking.init();

            saUtils.init(pageKey);
            saUtils.update({
                jsbrnd: this.jsbrnd,
            });
            saUtils.pv();
        },
    },
};
</script>
<style scoped lang="less">
.payment_box {
    padding: 35px 18px;
    .title {
        font-size: 34px;
        text-align: left;
        margin-bottom: 35px;
    }
    .pay_box {
        display: flex;
        justify-content: space-between;
        .item {
            width: 22.42%;
            height: 35px;
            line-height: 35px;
            color: #009bff;
            background-color: #eff2f5;
            border-radius: 4px;
            font-size: 17px;
            &.active {
                background-image: linear-gradient(
                    90deg,
                    #40b9ff 0%,
                    #009bff 100%
                );
                box-shadow: 0 2px 15px 0 rgba(0, 155, 255, 0.4);
                color: #fff;
            }
        }
    }
    .inter {
        margin-top: 20px;
        height: 58px;
        display: flex;
        align-items: center;
        position: relative;
        &:before {
            content: '元';
            font-size: 17px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
        }
        input {
            border: none;
            font-size: 17px;
        }
        input::-webkit-input-placeholder {
            color: #ddd;
        }
    }
    .textarea {
        h4 {
            font-size: 17px;
            text-align: left;
        }
        margin-top: 35px;
        align-items: center;
        padding-bottom: 20px;
        input {
            margin-top: 20px;
            width: 100%;
            border: none;
            font-size: 17px;
        }
        input::-webkit-input-placeholder {
            color: #ddd;
        }
    }
    .submit_btn {
        margin-top: 70px;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
        box-shadow: 0 4px 30px 0 rgba(0, 155, 255, 0.4);
        border-radius: 4px;
        font-size: 18px;
        border: none;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
    }
    .green_doctor {
        margin-top: 60px;
        background: #f6f6f6;
        padding: 15px;
        border-radius: 5px;
        .dec {
            text-align: left;
            line-height: 1.5;
            font-size: 12px;
            span {
                color: #ed9f39;
            }
        }
        .select-box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            font-size: 11px;
            color: #aaa;
            i {
                display: inline-block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid #aaa;
                margin-right: 3px;
                &.select {
                    position: relative;
                    border: 1px solid #009bff;
                    &:after {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #009bff;
                        position: absolute;
                        left: 2px;
                        top: 2px;
                    }
                }
            }
            span {
                color: #009bff;
            }
        }
    }
}
</style>
