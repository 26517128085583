import { paySubmit } from './api';
import store from 'store';
import getPaysuccessUri from './getPaysuccessUri';

const redirectUrl = `${window.BasePath}fund/project/paying`; // ?trade_no=123
let tzUrl;
export default async function pay(params) {
    const {
        id,
        amount,
        isSelect,
        pay_type,
        payment_type,
        anonymous,
        comments,
        extra_data,
        success,
        fail,
        cancel
    } = params;

    const goods = [{
        entity_id: Number(id),
        amount: amount,
        type: "penny"
    }, ];

    const randomBless = () => {
        const blessArr = [
            '祝你早日康复！',
            '一切都会好起来的，加油！',
            '相信明天会更好！',
            '加油！坚持就是胜利！',
            '加油，我们都支持你！',
            '风雨过后一定能见到彩虹！',
            '爱的力量一定能帮你战胜病魔！',
            '你要坚信一切都会过去的！',
            '带着我们的鼓励坚持下去！',
            '不放弃就一定有奇迹！',
        ];
        return blessArr[Math.floor(Math.random() * (blessArr.length))];
    }
    const preSubmit = await paySubmit({
        // project_uuid,
        // amount: amount,
        goods,
        pay_type,
        payment_type,
        anonymous,
        comments: comments || randomBless(),
        extra_data: '{}',
    });
    const callback = {
        success: () => {
            store.remove('nowxsuccess');
            window.setTimeout(function() {
                window.location.replace(tzUrl);
            }, 300);
            success && typeof success === 'function' && success();
        },
        fail: async() => {
            store.remove('nowxsuccess');
            fail && typeof fail === 'function' && fail();
        },
        cancel: () => {
            store.remove('nowxsuccess');
            cancel && typeof cancel === 'function' && cancel();
        }
    };
    const payDate = Object.assign(preSubmit, callback);

    if (payDate.code === 0) {
        const { trade_no, order_nos } = payDate.data;
        tzUrl = getPaysuccessUri({
            trade_no,
            id,
            amount,
            order_no: order_nos[0],
            isSelect: isSelect ? 1 : 0
        })
        store.set("nowxsuccess", {
            tzUrl: tzUrl
        });
    } else {
        // 错误提示 退出执行
        return
    }
    if (pay_type === 'h5') {
        wxPayH5(payDate);
    } else {
        wxPayControl(payDate);
    }

}


/**
 * 微信支付
 * @params payDate  { appid, time_stamp, nonce_str, package, pay_sign }
 * @params success
 * @params fail
 * @params cancel
 *
 * **/
function wxPayControl(response) {
    const {
        data,
        success,
        fail,
        cancel
    } = response || {};
    // 检测微信环境
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": data.wx.app_id, //公众号名称，由商户传入
                "timeStamp": data.wx.time_stamp, //时间戳，自1970年以来的秒数
                "nonceStr": data.wx.nonce_str, //随机串
                "package": data.wx.package,
                "signType": data.wx.sign_type, //微信签名方式：
                "paySign": data.wx.pay_sign //微信签名
            },
            function(res) {
                var callbackParam = {
                    status: 0
                };
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    callbackParam.status = 1;
                    success && typeof success === 'function' && success();
                } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                    callbackParam.status = -1;
                    cancel && typeof cancel === 'function' && cancel();
                } else if (callbackParam.status === 0) {
                    fail && typeof fail === 'function' && fail();
                }
            }
        );
    }
}

/**
 *  浏览器微信H5支付
 * **/
function wxPayH5(response) {
    const {
        data
    } = response || {};
    window.location.replace(createUrl(data.h5.mweb_url, {
        redirect_url: encodeURIComponent(`${redirectUrl}?tradeno=${data.trade_no}&projuuid=${projuuid}`),
    }));
}


/**
 * 重新组装url
 * @param {String} url url字符串
 * @param {Object} param 参数
 */
function createUrl(url, param) {
    if (!url) {
        alertMsg('url is not defined!');
        return;
    }
    var newParam = [];
    for (var i in param) {
        if (param[i] !== '' && typeof param[i] !== 'undefined') {
            newParam.push(i + '=' + param[i]);
        }
    }
    if (newParam.length) {
        return url + "&" + newParam.join('&');
    } else {
        return url;
    }
}