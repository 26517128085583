import { mmcAjax } from '@/common/js/mmcModule';

export function perCheck({ env, redirect_url, type }) {
    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/pay/pre/check`,
        data: {
            env,
            redirect_url,
            type: type
        },
        crossDomain: true,
    })
}


export function checkHelped(n) {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/help/check/${n}`,

        crossDomain: true,
    })
}

export function paySubmit(data) {

    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/pay/create/order`,
        data,
        crossDomain: true,
    })
}